exports.components = {
  "component---src-pages-2019-tsx": () => import("./../../../src/pages/2019.tsx" /* webpackChunkName: "component---src-pages-2019-tsx" */),
  "component---src-pages-2020-tsx": () => import("./../../../src/pages/2020.tsx" /* webpackChunkName: "component---src-pages-2020-tsx" */),
  "component---src-pages-2022-tsx": () => import("./../../../src/pages/2022.tsx" /* webpackChunkName: "component---src-pages-2022-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-europe-tsx": () => import("./../../../src/pages/europe.tsx" /* webpackChunkName: "component---src-pages-europe-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-wedding-part-one-tsx": () => import("./../../../src/pages/wedding-part-one.tsx" /* webpackChunkName: "component---src-pages-wedding-part-one-tsx" */),
  "component---src-pages-wedding-part-two-tsx": () => import("./../../../src/pages/wedding-part-two.tsx" /* webpackChunkName: "component---src-pages-wedding-part-two-tsx" */)
}

